import React from 'react'
import Link from 'gatsby-link'

import Layout from "../components/Layout"
import HomepageHero from '../components/HomepageHero'

export default class IndexPage extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <HomepageHero />
          <section className="lightest-gray-bg aga-hp-case-study-container">
            <section className="aga-container">
              <section className="aga-hp-para-container">
                <p>Inflammatory bowel disease, or IBD, is a lifelong disease associated with decreased quality of life, substantial morbidity including anemia, depression and cancer, and complications requiring hospitalization and surgery. Delays in accurate diagnosis and initiation of treatment in IBD are common and associated with worsened quality of life and with reduced response to medical therapy.</p>
                <p>For optimal patient care, it is critical that IBD is properly diagnosed as quickly as possible and that patients are promptly and appropriately referred to a gastroenterologist to initiate treatment.</p>
                <p>Primary care practitioners are usually first to encounter patients with IBD and they will deliver the majority of the patients’ health care.</p>
                <p>This site will provide primary care practitioners with education and tools to expedite the correct diagnosis of IBD and referrals to gastroenterologists.</p>
                <Link className="aga-button-large orange-bg aga-hp-button" to="/cases">
                  View Case Studies
                </Link>
              </section>
            </section>
          </section>
        </Layout>
      </div>
    )
  }
}
