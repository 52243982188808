import React from 'react'
import heroImage from '../../images/aga-hero.jpg'

export default class HomepageHero extends React.Component {

  // Handles fade-in by detecing when the component fully mounts, then doing fade-in
  componentDidMount() {
    document.getElementById('aga-hero-anchor').classList.add('aga-fade-in')
  }
  
  render() {

    // Setup inline style objects using ES6 template literals which pull in the correct paths from the content page itself
    const heroBackground = { 
      backgroundImage: `url(${ heroImage })`,
      maxWidth: '1200px',
      margin: '0 auto'
    }

    return (
      <div>
        <section className="aga-hp-container" id="aga-hero-anchor">
          <section className="aga-container-vertical aga-hero-container" style={ heroBackground }>
            <h1 className="aga-hero-title">Better IBD Care <span>Through Co-Management</span></h1>
          </section>
        </section>
      </div>
    )
  }
}